const mutations = {
  SET_DRAWER_STATE(state, payload) {
    state.isDrawerOpen = payload;
    state.lockBodyScroll = payload;
  },
  SET_LOCK_BODY_SCROLL(state, payload) {
    state.lockBodyScroll = payload;
  },
  SET_NAV_TITLLE(state, payload) {
    state.navTitle = payload;
  },
};

export default mutations;
