<template>
  <div id="app">
    <router-view />
    <notifications
      position="top center"
      class="mask"
    />
    <BackToTop
      text="Back to top"
      visibleoffset="55"
    >
      <img
        src="@/assets/image/returntop/top.png"
        alt=""
      >
    </BackToTop>
  </div>
</template>

<script>
import BackToTop from 'vue-backtotop';

export default {
  components: {
    BackToTop,
  },
  computed: {
    lockBodyScroll() {
      return this.$store.state.app.lockBodyScroll;
    },
  },
  watch: {
    $route() {
      this.$store.commit('SET_LOCK_BODY_SCROLL', false);
      this.$store.commit('SET_DRAWER_STATE', false);
    },
    lockBodyScroll(value) {
      if (value) {
        document.body.setAttribute('style', 'overflow: hidden;');
      } else {
        setTimeout(() => {
          document.body.removeAttribute('style');
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-back-to-top {
  z-index: 3;
  img {
    width: 70px;
    height: 70px;
  }
}
.mask {
  ::v-deep
  .notification-title {
    text-align: center;
  }
}
</style>
