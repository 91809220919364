<template>
  <div class="guide">
    <div class="guide-box">

      <div class="guide-logo">
        <img
          src="@/assets/image/guide/logo.png"
          alt=""
        >
      </div>

      <div class="btn">
        <router-link to="/home">
          {{$t('guide.entrance')}} <img src="@/assets/image/jiantou.png" alt="">
        </router-link>
      </div>

      <div class="language">
        <button
          v-for="(item, index) in locales"
          :key="index"
          href=""
          @click.prevent="onClickLanguage(item.id,index)"
          :style="LanguageIndex === item.id?'color:#fff;border: 1px solid #fff;':''"
        >
          <span>{{item.name}}</span>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      LanguageIndex: 'cn',
      locales: [
        {
          id: 'cn',
          name: '中文',
        },
        {
          id: 'en',
          name: 'EN',
        },
      ],
    };
  },
  methods: {
    onClickLanguage(languageID) {
      this.LanguageIndex = languageID;
      if (languageID === 'en') {
        if (this.$i18n.locale === 'en') {
          return;
        }

        this.$i18n.locale = 'en';
        this.$router.replace({
          query: {
            lang: this.$i18n.locale,
          },
        });
      } else if (languageID === 'cn') {
        if (this.$i18n.locale === 'cn') {
          return;
        }

        this.$i18n.locale = 'cn';
        this.$router.replace({
          query: {
            lang: this.$i18n.locale,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.guide {
  background: url('~@/assets/image/guide/banner.png') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #cccccc;
  height: 100vh;
  max-width: 100%;
  min-width: 100%;
  width: 100%;

  &-box {
    padding: 0 70px;

    .guide-logo {
      padding-top: 6.25rem;
      text-align: center;
      img {
        height: 120px;
      }
    }

    .btn {
           padding-top: 5.75rem;
           text-align: center;
       a {
            font-size: 30px;
            padding: 24px 80px;
            border-radius: 50px;
            background: #294867;
            outline: none;
              background: #ffbf00;
              color: #333;
              border: 2px solid transparent;
              cursor: pointer;

            i {
              margin-left: 20px;
            }
          }
    }

    .language {
      margin-top: 4rem;
      text-align: center;

      button {
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        background: transparent;
        outline: none;
        color: #818387;
        border: 1px solid #818387;
        border-radius: 50%;
        margin-right: 30px;
        font-size: 15px;

        &:hover {
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }
}
</style>
