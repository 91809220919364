import Vue from 'vue';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/libs/rem';
import '@/assets/css/index.css';
import { createProvider } from './vue-apollo';
import i18n from './i18n';

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  i18n,
  render: (h) => h(App),
}).$mount('#app');
