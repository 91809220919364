import Vue from 'vue';
import VueRouter from 'vue-router';
import Guide from '@/views/guide';
import store from '../store';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
  {
    // 网站首页
    path: '/',
    name: 'guide',
    component: Guide,
    meta: {
      title: 'nav-title.home',
    },
  },
  {
    // 网站首页
    path: '/home',
    name: 'home',
    component: () => import('../views/homepage'),
  },
  {
    // 网站头部导航
    path: '/head-nav',
    name: 'head-nav',
    component: () => import('../views/head-nav'),
  },
  {
    // 网站底部
    path: '/footers',
    name: 'footers',
    component: () => import('../views/footers'),
  },

  {
    // 产品分类
    path: '/product-sort',
    name: 'product-sort',
    props: true,
    component: () => import('../views/product-sort'),
    meta: {
      title: 'nav-title.product',
    },
  },
  {
    // 产品分类
    path: '/product-sort-details/:listId',
    name: 'product-sort-details',
    props: true,
    component: () => import('../views/product-sort-details'),
    meta: {
      title: 'nav-title.product',
    },
  },

  {
    // 关于我们
    path: '/about-qualifications',
    name: 'about',
    component: () => import('../views/about-qualifications'),
    meta: {
      title: 'nav-title.about',
    },
  },

  {
    // 关于我们-公司发展历程
    path: '/about-development',
    name: 'about-development',
    component: () => import('../views/about-development'),
    meta: {
      title: 'nav-title.development',
    },
  },
  {
    // 关于我们-公司简介
    path: '/about-introduce',
    name: 'about-introduce',
    component: () => import('../views/about-introduce'),
    meta: {
      title: 'nav-title.introduce',
    },
  },
  {
    // 关于我们-公司理念
    path: '/about-idea',
    name: 'about-idea',
    component: () => import('../views/about-idea'),
    meta: {
      title: 'nav-title.idea',
    },
  },
  {
    // 关于我们-公司目标
    path: '/about-target',
    name: 'about-target',
    component: () => import('../views/about-target'),
    meta: {
      title: 'nav-title.target',
    },
  },
  {
    // 关于我们-公司新闻
    path: '/about-news',
    name: 'about-news',
    component: () => import('../views/about-news'),
    meta: {
      title: 'nav-title.news',
    },
  },
  {
    // 关于我们-公司新闻详情
    path: '/about-news-details/:id',
    name: 'about-news-details',
    props: true,
    component: () => import('../views/about-news-details'),
    meta: {
      title: 'nav-title.news',
    },
  },
  {
    // 关于我们-供应商合作
    path: '/about-cooperation',
    name: 'about-cooperation',
    component: () => import('../views/about-cooperation'),
    meta: {
      title: 'nav-title.cooperation',
    },
  },
  {
    // QHSE
    path: '/qhse',
    name: 'qhse',
    component: () => import('../views/qhse'),
    meta: {
      title: 'nav-title.qhse',
    },
  },
  {
    // 品质保证
    path: '/quality-assurance',
    name: 'quality-assurance',
    component: () => import('../views/quality-assurance'),
    meta: {
      title: 'nav-title.quality-assurance',
    },
  },
  {
    // 生产能力
    path: '/throughput',
    name: 'throughput',
    component: () => import('../views/throughput'),
    meta: {
      title: 'nav-title.throughput',
    },
  },
  {
    // 市场分析
    path: '/market-analysis',
    name: 'market-analysis',
    component: () => import('../views/market-analysis'),
    meta: {
      title: 'nav-title.market-analysis',
    },
  },
  {
    // 联系我们
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact'),
    meta: {
      title: 'nav-title.contact',
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta?.title;
  if (title) {
    document.title = i18n.t(title);
    store.commit('SET_NAV_TITLLE', title);
  }
  next();
});

export default router;
