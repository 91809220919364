// 设置 rem 函数
const MinWidth = 375;

function setRem() {
  // 假设页面宽度为375; 1rem = 15px
  const htmlWidth = Math.max(
    document.documentElement.clientWidth || document.body.clientWidth, MinWidth,
  );
  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName('html')[0];
  // 设置根元素字体大小
  const colCount = Math.floor((750 / 28) * 100) / 100;
  htmlDom.style.fontSize = `${htmlWidth / colCount}px`;
}

// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = () => {
  setRem();
};
